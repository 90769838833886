import React, { useState, useEffect } from "react";
import { BASE_URL } from "./Base_url.js";
import useToken from "./TokenContext.js";






const DayDisplay = ({ daysReport }) => {
  const [selectedDate, setSelectedDate] = useState(null);

  // Update selectedDate only when daysReport changes significantly
  useEffect(() => {
    if (daysReport && !selectedDate) {
      const today = new Date().toISOString().split('T')[0];
      const dates = Object.keys(daysReport).sort();
      // If today exists in the data, use it; otherwise use the most recent date
      setSelectedDate(dates.includes(today) ? today : dates[dates.length - 1]);
    }
  }, [daysReport, selectedDate]);

  // Function to get active classes (those with any activity)
  const getActiveClasses = (data) => {
    if (!data) return [];
    const activeClasses = new Set();
    Object.values(data).forEach(day => {
      Object.entries(day.poms_classes).forEach(([className, count]) => {
        if (count > 0) activeClasses.add(className);
      });
      Object.entries(day.tasks_classes).forEach(([className, count]) => {
        if (count > 0) activeClasses.add(className);
      });
    });
    return Array.from(activeClasses).filter(className => className !== '');
  };

  // Generate colors for active classes
  const colorPalette_3 = ['#ef5656',  '#ffd54f','#64b5f6', ];
  const colorPalette_6 = ['#ef5656', '#ffd54f',  '#81c784',      '#64b5f6',  '#cd70ff', "#ff589e"];
  const colorPalette_9 = ['#ef5656', '#ff8f50',  '#ffd54f',  '#81c784', '#4db6ac',      '#64b5f6',  '#6480f6',  '#cd70ff',  "#ff589e"];
  const colorPalette_12 = ['#ef5656', '#ff8f50', '#ffb648', '#ffd54f', '#b0d169', '#81c784', '#4db6ac',      '#64b5f6',  '#6480f6', '#7d64f6',  '#cd70ff', "#ff589e"];
  const colorPalette_15 = ['#ef5656', '#ff8f50', '#ffb648', '#ffd54f', '#b0d169', '#81c784', '#4db6ac',      '#64b5f6', '#4f90ff', '#6480f6', '#7d64f6', '#a464f6', '#cd70ff', '#f876cf', "#ff589e"];


  const colorPalette = ['#ef5656', '#ff8f50', '#ffb648', '#ffd54f', '#b0d169', '#81c784', '#4db6ac',      '#64b5f6', '#4f90ff', '#6480f6', '#7d64f6', '#a464f6', '#cd70ff', '#f876cf', "#ff589e"];
  const activeClasses = daysReport ? getActiveClasses(daysReport) : [];
  const getColorPalette = (classCount) => {
    if (classCount <= 3) return colorPalette_3;
    if (classCount <= 6) return colorPalette_6;
    if (classCount <= 9) return colorPalette_9;
    if (classCount <= 12) return colorPalette_12;
    return colorPalette_15;
  };

  const classColors = Object.fromEntries(
    activeClasses.map((className, index) => {
      const selectedPalette = getColorPalette(activeClasses.length);
      return [className, selectedPalette[index % selectedPalette.length]];
    })
  );

  // Function to calculate maximum height for scaling
  const calculateMaxHeight = () => {
    let maxTotal = 0;
    Object.values(daysReport).forEach(day => {
      const tasksTotal = Object.values(day.tasks_classes)
        .reduce((sum, val) => sum + val, 0);
      const pomsTotal = Object.values(day.poms_classes)
        .reduce((sum, val) => sum + val, 0);
      maxTotal = Math.max(maxTotal, tasksTotal, pomsTotal);
    });
    return maxTotal;
  };

  // Get dates sorted chronologically
  const dates = daysReport ? Object.keys(daysReport).sort() : [];
  const lastSevenDays = dates.slice(-7);

  // Return loading state if data isn't ready
  if (!daysReport || !selectedDate) {
    return <div style={{ color: 'ivory' }}>Loading...</div>;
  }

  // Function to get day name from date
  const getDayName = (dateString) => {
    const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const date = new Date(dateString);
    return days[date.getDay()];
  };

  // Function to get next/previous date
  const changeDate = (direction) => {
    const sortedDates = Object.keys(daysReport).sort();
    const currentIndex = sortedDates.indexOf(selectedDate);
    if (direction === 'next' && currentIndex < sortedDates.length - 1) {
      setSelectedDate(sortedDates[currentIndex + 1]);
    } else if (direction === 'prev' && currentIndex > 0) {
      setSelectedDate(sortedDates[currentIndex - 1]);
    }
  };

  // Calculate metrics for selected day
  const selectedDayData = daysReport[selectedDate];
  const totalMinutes = selectedDayData.total_time;



  

  return (
    <div className="statistics-container" style={{ 
      padding: '0',
      color: 'ivory',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#2b2b2b',
      width: '100%',
      height: '90vh',
      position: 'relative'
    }}>
      {/* Chart container */}
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '4vw',
        width: '90%',
        maxWidth: '1400px',
        margin: '0 auto'
      }}>
        {/* Left side - Legend */}
        <div style={{ 
          paddingBottom: '3vh',
          fontSize: '1.2vw',
          width: '12vw'
        }}>
          {activeClasses.map(cls => (
            <div key={cls} style={{ 
              display: 'flex', 
              alignItems: 'center', 
              marginBottom: '1vh' 
            }}>
              <div style={{ 
                width: '1.5vw', 
                height: '1.5vw', 
                backgroundColor: classColors[cls], 
                marginRight: '1vw',
                borderRadius: '3px'
              }}></div>
              <span>{cls}</span>
            </div>
          ))}
        </div>

        {/* Center - Chart */}
        <div style={{ 
          display: 'flex', 
          flexDirection: 'column',
          alignItems: 'center',
          width: '50vw',
          maxWidth: '800px',
          margin: '0'
        }}>
          {/* Navigation */}
          <div style={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: '2vw',
            marginBottom: '8vh',
            marginTop: '-25vh',
            transform: 'scale(1.5)'
          }}>
            <button 
              onClick={() => changeDate('prev')}
              style={{ 
                fontSize: '2.5vw',
                background: 'none', 
                border: 'none', 
                color: 'ivory',
                cursor: 'pointer',
                opacity: Object.keys(daysReport).sort().indexOf(selectedDate) > 0 ? 1 : 0.5
              }}
            >←</button>
            <h2 style={{ 
              margin: 0, 
              color: 'ivory', 
              fontSize: '2.5vw'
            }}>{getDayName(selectedDate)}</h2>
            <button 
              onClick={() => changeDate('next')}
              style={{ 
                fontSize: '2.5vw',
                background: 'none', 
                border: 'none', 
                color: 'ivory',
                cursor: 'pointer',
                opacity: Object.keys(daysReport).sort().indexOf(selectedDate) < Object.keys(daysReport).length - 1 ? 1 : 0.5
              }}
            >→</button>
          </div>

          {/* Graph area */}
          <div style={{ 
            display: 'flex',
            alignItems: 'flex-end',
            height: '40vh',
            position: 'relative',
            justifyContent: 'center'
          }}>
            {/* Y-axis */}
            <div style={{ 
              display: 'flex', 
              flexDirection: 'column', 
              justifyContent: 'space-between', 
              height: '100%',
              marginRight: '1vw',
              fontSize: '1.2vw'
            }}>
              {[...Array(6)].map((_, i) => {
                const value = Math.round((calculateMaxHeight() * (5 - i)) / 5);
                return (
                  <span key={i}>{`${value}|${value * 25}`}</span>
                );
              })}
            </div>

            {/* Bars */}
            <div style={{ 
              display: 'flex', 
              gap: '2vw', 
              height: '100%',
              alignItems: 'flex-end'
            }}>
              {lastSevenDays.map((date) => {
                const dayData = daysReport[date];
                return (
                  <div key={date} style={{ 
                    display: 'flex', 
                    gap: '0.5vw', 
                    height: '100%',
                    opacity: date === selectedDate ? 1 : 0.7, // Highlight selected day
                    transition: 'opacity 0.3s ease'
                  }}>
                    {/* Tasks bar */}
                    <div style={{ width: '1.5vw', display: 'flex', flexDirection: 'column-reverse' }}>
                      {activeClasses.map(className => (
                        dayData.tasks_classes[className] > 0 && (
                          <div 
                            key={`${date}-${className}-tasks`}
                            style={{ 
                              height: `${(dayData.tasks_classes[className] / calculateMaxHeight()) * 40}vh`,
                              backgroundColor: classColors[className],
                              borderRadius: '3px',
                              marginBottom: '-1px'
                            }}
                          />
                        )
                      ))}
                    </div>
                    {/* Pomodoros bar */}
                    <div style={{ width: '1.5vw', display: 'flex', flexDirection: 'column-reverse' }}>
                      {activeClasses.map(className => (
                        dayData.poms_classes[className] > 0 && (
                          <div 
                            key={`${date}-${className}-poms`}
                            style={{ 
                              height: `${(dayData.poms_classes[className] / calculateMaxHeight()) * 40}vh`,
                              backgroundColor: classColors[className],
                              borderRadius: '3px',
                              marginBottom: '-1px'
                            }}
                          />
                        )
                      ))}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {/* X-axis */}
          <div style={{ 
            display: 'flex', 
            gap: '2vw',
            marginTop: '1vh',
            color: 'ivory',
            width: '100%',
            paddingLeft: '4vw',
            fontSize: '1.2vw',
            justifyContent: 'center'
          }}>
            {[
              { day: 'm', label: 'Mon' },
              { day: 't1', label: 'Tue' },
              { day: 'w', label: 'Wed' },
              { day: 't2', label: 'Thu' },
              { day: 'f', label: 'Fri' },
              { day: 's1', label: 'Sat' },
              { day: 's2', label: 'Sun' }
            ].map(({ day, label }) => (
              <div key={day} style={{
                width: '3.5vw',
                textAlign: 'center'
              }}>
                {label[0].toLowerCase()}
              </div>
            ))}
          </div>
        </div>

        {/* Right side - Metrics */}
        <div style={{ 
          paddingBottom: '27vh',
          textAlign: 'right',
          width: '12vw'
        }}>
          <div style={{ 
            display: 'flex', 
            flexDirection: 'column',
            gap: '2vh'
          }}>
            <div style={{ position: 'relative' }}>
              <h1 style={{ fontSize: '7vw', margin: '0' }}>{selectedDayData.tasks_done}</h1>
              <span style={{ 
                position: 'absolute',
                bottom: '-1vw',
                right: '-2vw',
                fontSize: '2vw',
                color: 'ivory',
                opacity: 0.8
              }}>tasks</span>
            </div>
            <div style={{ position: 'relative' }}>
              <h1 style={{ fontSize: '7vw', margin: '0' }}>{selectedDayData.pomodoros_done}</h1>
              <span style={{ 
                position: 'absolute',
                bottom: '-1vw',
                right: '-2vw',
                fontSize: '2vw',
                color: 'ivory',
                opacity: 0.8
              }}>poms</span>
            </div>
            <div style={{ position: 'relative' }}>
              <h1 style={{ fontSize: '7vw', margin: '0' }}>{totalMinutes}</h1>
              <span style={{ 
                position: 'absolute',
                //bottom: '0',
                right: '-2vw',
                bottom: '-1vw',
                fontSize: '2vw',
                color: 'ivory',
                opacity: 0.8
              }}>mins</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const WeekDisplay = ({ weeksReport }) => {
  const [selectedWeek, setSelectedWeek] = useState(null);

  useEffect(() => {
    const weeks = Object.keys(weeksReport).sort();
    setSelectedWeek(weeks[weeks.length - 1]);
  }, [weeksReport]);

  // Function to get active classes (those with any activity)
  const getActiveClasses = (data) => {
    if (!data) return [];
    const activeClasses = new Set();
    Object.values(data).forEach(week => {
      Object.entries(week.poms_classes).forEach(([className, count]) => {
        if (count > 0) activeClasses.add(className);
      });
      Object.entries(week.tasks_classes).forEach(([className, count]) => {
        if (count > 0) activeClasses.add(className);
      });
    });
    return Array.from(activeClasses).filter(className => className !== '');
  };

  const colorPalette_3 = ['#ef5656',  '#ffd54f','#64b5f6', ];
  const colorPalette_6 = ['#ef5656', '#ffd54f',  '#81c784',      '#64b5f6',  '#cd70ff', "#ff589e"];
  const colorPalette_9 = ['#ef5656', '#ff8f50',  '#ffd54f',  '#81c784', '#4db6ac',      '#64b5f6',  '#6480f6',  '#cd70ff',  "#ff589e"];
  const colorPalette_12 = ['#ef5656', '#ff8f50', '#ffb648', '#ffd54f', '#b0d169', '#81c784', '#4db6ac',      '#64b5f6',  '#6480f6', '#7d64f6',  '#cd70ff', "#ff589e"];
  const colorPalette_15 = ['#ef5656', '#ff8f50', '#ffb648', '#ffd54f', '#b0d169', '#81c784', '#4db6ac',      '#64b5f6', '#4f90ff', '#6480f6', '#7d64f6', '#a464f6', '#cd70ff', '#f876cf', "#ff589e"];

  const colorPalette = ['#E57373', '#FFB74D', '#81C784', '#64B5F6', '#BA68C8', '#4DB6AC', '#FFD54F', '#F06292'];
  const activeClasses = weeksReport ? getActiveClasses(weeksReport) : [];
  const getColorPalette = (classCount) => {
    if (classCount <= 3) return colorPalette_3;
    if (classCount <= 6) return colorPalette_6;
    if (classCount <= 9) return colorPalette_9;
    if (classCount <= 12) return colorPalette_12;
    return colorPalette_15;
  };

  const classColors = Object.fromEntries(
    activeClasses.map((className, index) => {
      const selectedPalette = getColorPalette(activeClasses.length);
      return [className, selectedPalette[index % selectedPalette.length]];
    })
  );

  const calculateMaxHeight = () => {
    let maxTotal = 0;
    Object.values(weeksReport || {}).forEach(week => {
      const tasksTotal = Object.values(week.tasks_classes)
        .reduce((sum, val) => sum + val, 0);
      const pomsTotal = Object.values(week.poms_classes)
        .reduce((sum, val) => sum + val, 0);
      maxTotal = Math.max(maxTotal, tasksTotal, pomsTotal);
    });
    return maxTotal;
  };

  const formatDateRange = (startDate) => {
    const start = new Date(startDate);
    const end = new Date(weeksReport[startDate].week_end);
    return `${start.getMonth() + 1}/${start.getDate()} - ${end.getMonth() + 1}/${end.getDate()}`;
  };

  const changeWeek = (direction) => {
    const sortedWeeks = Object.keys(weeksReport).sort();
    const currentIndex = sortedWeeks.indexOf(selectedWeek);
    if (direction === 'next' && currentIndex < sortedWeeks.length - 1) {
      setSelectedWeek(sortedWeeks[currentIndex + 1]);
    } else if (direction === 'prev' && currentIndex > 0) {
      setSelectedWeek(sortedWeeks[currentIndex - 1]);
    }
  };

  if (!weeksReport || !selectedWeek) {
    return <div style={{ color: 'ivory' }}>Loading...</div>;
  }

  const selectedWeekData = weeksReport[selectedWeek];
  const totalMinutes = selectedWeekData.total_time;
  const weeks = Object.keys(weeksReport).sort();

  return (
    <div className="statistics-container" style={{ 
      padding: '0',
      color: 'ivory',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#2b2b2b',
      width: '100%',
      height: '90vh',
      position: 'relative'
    }}>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '4vw',
        width: '90%',
        maxWidth: '1400px',
        margin: '0 auto'
      }}>
        {/* Left side - Legend */}
        <div style={{ 
          paddingBottom: '3vh',
          fontSize: '1.2vw',
          width: '12vw'
        }}>
          {activeClasses.map(cls => (
            <div key={cls} style={{ 
              display: 'flex', 
              alignItems: 'center', 
              marginBottom: '1vh' 
            }}>
              <div style={{ 
                width: '1.5vw', 
                height: '1.5vw', 
                backgroundColor: classColors[cls], 
                marginRight: '1vw',
                borderRadius: '3px'
              }}></div>
              <span>{cls}</span>
            </div>
          ))}
        </div>

        {/* Center - Chart */}
        <div style={{ 
          display: 'flex', 
          flexDirection: 'column',
          alignItems: 'center',
          width: '50vw',
          maxWidth: '800px',
          margin: '0'
        }}>
          {/* Navigation */}
          <div style={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: '2vw',
            marginBottom: '8vh',
            marginTop: '-25vh',
            transform: 'scale(1.5)'
          }}>
            <button 
              onClick={() => changeWeek('prev')}
              style={{ 
                fontSize: '2.5vw',
                background: 'none', 
                border: 'none', 
                color: 'ivory',
                cursor: 'pointer',
                opacity: Object.keys(weeksReport).sort().indexOf(selectedWeek) > 0 ? 1 : 0.5
              }}
            >←</button>
            <h2 style={{ 
              margin: 0, 
              color: 'ivory', 
              fontSize: '2.5vw'
            }}>{formatDateRange(selectedWeek)}</h2>
            <button 
              onClick={() => changeWeek('next')}
              style={{ 
                fontSize: '2.5vw',
                background: 'none', 
                border: 'none', 
                color: 'ivory',
                cursor: 'pointer',
                opacity: Object.keys(weeksReport).sort().indexOf(selectedWeek) < Object.keys(weeksReport).length - 1 ? 1 : 0.5
              }}
            >→</button>
          </div>

          {/* Graph area */}
          <div style={{ 
            display: 'flex',
            alignItems: 'flex-end',
            height: '40vh',
            position: 'relative',
            justifyContent: 'center'
          }}>
            {/* Y-axis */}
            <div style={{ 
              display: 'flex', 
              flexDirection: 'column', 
              justifyContent: 'space-between', 
              height: '100%',
              marginRight: '1vw',
              fontSize: '1.2vw'
            }}>
              {[...Array(6)].map((_, i) => {
                const value = Math.round((calculateMaxHeight() * (5 - i)) / 5);
                return (
                  <span key={i}>{`${value}|${value * 25}`}</span>
                );
              })}
            </div>

            {/* Bars */}
            <div style={{ 
              display: 'flex', 
              gap: '2vw', 
              height: '100%',
              alignItems: 'flex-end'
            }}>
              {weeks.map((week) => {
                const weekData = weeksReport[week];
                return (
                  <div key={week} style={{ 
                    display: 'flex', 
                    gap: '0.5vw', 
                    height: '100%',
                    opacity: week === selectedWeek ? 1 : 0.7,
                    transition: 'opacity 0.3s ease'
                  }}>
                    {/* Tasks bar */}
                    <div style={{ width: '1.5vw', display: 'flex', flexDirection: 'column-reverse' }}>
                      {activeClasses.map(className => (
                        weekData.tasks_classes[className] > 0 && (
                          <div 
                            key={`${week}-${className}-tasks`}
                            style={{ 
                              height: `${(weekData.tasks_classes[className] / calculateMaxHeight()) * 40}vh`,
                              backgroundColor: classColors[className],
                              borderRadius: '3px',
                              marginBottom: '-1px'
                            }}
                          />
                        )
                      ))}
                    </div>
                    {/* Pomodoros bar */}
                    <div style={{ width: '1.5vw', display: 'flex', flexDirection: 'column-reverse' }}>
                      {activeClasses.map(className => (
                        weekData.poms_classes[className] > 0 && (
                          <div 
                            key={`${week}-${className}-poms`}
                            style={{ 
                              height: `${(weekData.poms_classes[className] / calculateMaxHeight()) * 40}vh`,
                              backgroundColor: classColors[className],
                              borderRadius: '3px',
                              marginBottom: '-1px'
                            }}
                          />
                        )
                      ))}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {/* X-axis */}
          <div style={{ 
            display: 'flex', 
            gap: '2vw',
            marginTop: '1vh',
            color: 'ivory',
            width: '100%',
            paddingLeft: '4vw',
            fontSize: '1.2vw',
            justifyContent: 'center'
          }}>
            {weeks.map(week => {
              const date = new Date(week);
              return (
                <div key={week} style={{
                  width: '3.5vw',
                  textAlign: 'center'
                }}>
                  {`${date.getMonth() + 1}/${date.getDate()}`}
                </div>
              );
            })}
          </div>
        </div>

        {/* Right side - Metrics */}
        <div style={{ 
          paddingBottom: '27vh',
          textAlign: 'right',
          width: '12vw'
        }}>
          <div style={{ 
            display: 'flex', 
            flexDirection: 'column',
            gap: '2vh'
          }}>
            <div style={{ position: 'relative' }}>
              <h1 style={{ fontSize: '7vw', margin: '0' }}>{selectedWeekData.tasks_done}</h1>
              <span style={{ 
                position: 'absolute',
                bottom: '-1vw',
                right: '-2vw',
                fontSize: '2vw',
                color: 'ivory',
                opacity: 0.8
              }}>tasks</span>
            </div>
            <div style={{ position: 'relative' }}>
              <h1 style={{ fontSize: '7vw', margin: '0' }}>{selectedWeekData.pomodoros_done}</h1>
              <span style={{ 
                position: 'absolute',
                bottom: '-1vw',
                right: '-2vw',
                fontSize: '2vw',
                color: 'ivory',
                opacity: 0.8
              }}>poms</span>
            </div>
            <div style={{ position: 'relative' }}>
              <h1 style={{ fontSize: '7vw', margin: '0' }}>{totalMinutes}</h1>
              <span style={{ 
                position: 'absolute',
                right: '-2vw',
                bottom: '-1vw',
                fontSize: '2vw',
                color: 'ivory',
                opacity: 0.8
              }}>mins</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function Statistics() {
  const [display, setDisplay] = useState("days");
  const [daysData, setDaysData] = useState(null);
  const [weeksData, setWeeksData] = useState(null);
  const { profile, token, statisticsData } = useToken();

  // Update local state when statisticsData changes
  useEffect(() => {
    if (statisticsData.days) {
      setDaysData(statisticsData.days);
    }
    if (statisticsData.weeks) {
      setWeeksData(statisticsData.weeks);
    }
  }, [statisticsData]);

  // Fetch both datasets on component mount
  useEffect(() => {
    const fetchData = async () => {
      if (profile && profile[0]) {
        try {
          // Fetch days data
          const daysResponse = await fetch(
            `${BASE_URL}/api/report/days?username=${profile[0].username}`,
            {
              method: "GET",
              headers: {
                accept: "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          // Fetch weeks data
          const weeksResponse = await fetch(
            `${BASE_URL}/api/report/weeks?username=${profile[0].username}`,
            {
              method: "GET",
              headers: {
                accept: "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const daysJson = await daysResponse.json();
          const weeksJson = await weeksResponse.json();

          setDaysData(daysJson);
          setWeeksData(weeksJson);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, [profile, token]);

  return (
    <div style={{ 
      backgroundColor: '#2b2b2b', 
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      overflow: "hidden"
    }}>
      <div className="quercus">
        <button
          className={`kan_ban_headers`}
          style={{ opacity: display === "days" ? 1 : 0.5 }}
          onClick={() => setDisplay("days")}
        >
          Days
        </button>
        <button
          className={`calendar_header`}
          style={{ opacity: display === "weeks" ? 1 : 0.5 }}
          onClick={() => setDisplay("weeks")}
        >
          Weeks
        </button>
      </div>
      {profile && (
        display === "days" ? 
          <DayDisplay daysReport={daysData} /> : 
          <WeekDisplay weeksReport={weeksData} />
      )}
    </div>
  );
}
